import { template as template_7a570f50d689491085a2a6c58d19d866 } from "@ember/template-compiler";
const FKText = template_7a570f50d689491085a2a6c58d19d866(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
