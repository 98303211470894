import { template as template_54d1bc5bae104cd8bea74434647d9cce } from "@ember/template-compiler";
const FKLabel = template_54d1bc5bae104cd8bea74434647d9cce(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
