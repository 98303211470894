import { template as template_2274ff9bc9ec41b68097e4d4f183cb9b } from "@ember/template-compiler";
const FKControlMenuContainer = template_2274ff9bc9ec41b68097e4d4f183cb9b(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
